import PortfolioCategory from '@business/portfolio/PortfolioCategory';
import Home from '@components/home/Home';
import { useSetGlobalAppLanguage } from '@components/Hooks';
import Layout from '@components/layout/Layout';
import config, { AppLanguage } from '@config';
import AppSetting from '@xFrame4/business/AppSetting';
import { useTranslate } from '@xFrame4/components/Hooks';
import CustomHead from '@xFrame4/components/next/CustomHead';
import { GetStaticPaths, GetStaticProps } from 'next';
import { getCommonStaticProps, getPortfolioCategoriesForStaticProps, PageProps, PageWithPortfolioCategoriesProps } from 'pages';
import { ParsedUrlQuery } from 'querystring';
import { FunctionComponent } from 'react';

export interface PageWithLanguageParams extends ParsedUrlQuery
{
    language: string;
}

export interface PageWithLanguageProps extends PageProps
{
    language: AppLanguage;
}

export interface LanguagePageParams extends PageWithLanguageParams
{
    
}

export interface LanguagePageProps extends PageWithLanguageProps, PageWithPortfolioCategoriesProps
{
    
}

export const getStaticPaths: GetStaticPaths<LanguagePageParams> = async () =>
{
    let paths: Array<{ params: LanguagePageParams }> = [];

    for (let language of config.languages)
    {
        let path: { params: LanguagePageParams } = {
            params: {
                language: language.code
            }
        }
        paths.push(path);
    }

    return {
        paths,
        fallback: 'blocking',
    };
}

export const getStaticProps: GetStaticProps<LanguagePageProps, LanguagePageParams> = async (context) => 
{
    // language
    let language = config.languages.find(l => l.code == context.params?.language);
    if (language === undefined) language = config.defaultLanguage;
    
    // Common page props
    let commonPageProps = await getCommonStaticProps();

    return {
        props: {
           ...commonPageProps,
           language: language,
           portfolioCategories: await getPortfolioCategoriesForStaticProps(language.code)
        }
    };
}


const LanguagePage: FunctionComponent<LanguagePageProps> = (props) =>
{
    const t = useTranslate();
    useSetGlobalAppLanguage(props.language);
    
    /** Render */
    return (
        <>
            <CustomHead 
                metaData={{
                    title: t('APP_TITLE'),
                    metaDescription: t('HOME_META_DESC')
                }}
            />

            <Layout 
                menuItems={props.menuItems}
                appSettings={AppSetting.createArrayFromJson(props.appSettings) as AppSetting[]}
            >
                <Home portfolioCategories={PortfolioCategory.createArrayFromJson(props.portfolioCategories) as PortfolioCategory[]} />
            </Layout>
        </>
    );
}

export default LanguagePage;