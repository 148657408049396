import { AppLanguage } from '@config';
import { useAppActionDispatch } from '@redux/hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useEffect } from 'react';

export function useBuildMetaTitle(pageTitle?: string)
{
    const t = useTranslate();

    return t('APP_TITLE') + ' | ' + t(pageTitle ?? '');
}

/** 
 * Hook: set the global app language with Redux.
 */
export function useSetGlobalAppLanguage(language: AppLanguage)
{
    const dispatch = useAppActionDispatch();

    useEffect(() => {
        dispatch(setAppLanguage(language))
    }, [language]);
}